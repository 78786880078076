// src/components/Success.js
import React, { useEffect } from "react";
function Success({ onStart }) {
	useEffect(() => {
		const redirectTimeout = setTimeout(() => {
			onStart();
		}, 6000);
		return () => clearTimeout(redirectTimeout);
	}, []);
	return (
		<div className='congrats-overlay transparent'>
			<div className='congrats-message'>
				<h2>Congratulations!</h2>
				<h2>You have matched all the cards</h2>
				{/* <button className='try-again' onClick={onStart}>
					Start Again!
				</button> */}
			</div>
		</div>
	);
}

export default Success;
