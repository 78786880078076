// src/components/GameOver.js
import React, {useEffect } from "react";

function GameOver({ onStart }) {
	useEffect(() => {
		const redirectTimeout = setTimeout(() => {
			onStart();
		}, 6000);
		return () => clearTimeout(redirectTimeout);
	}, []);
	return (
		<div className='congrats-overlay'>
			<div className='congrats-message'>
				<h2>Sorry, you have run out of time !</h2>
				{/* <button className='try-again' onClick={onStart}>
					Try Again!
				</button> */}
			</div>
		</div>
	);
}

export default GameOver;
