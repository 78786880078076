// src/components/Welcome.js
import React, { useState, useRef, useEffect } from "react";

const VirtualKeyboard = ({ onKeyPress, type, isCapsLockOn, toggleCapsLock }) => {
	const keys = {
		text: [
			["q", "w", "e", "r", "t", "y", "u", "i", "o", "p"],
			["a", "s", "d", "f", "g", "h", "j", "k", "l"],
			["z", "x", "c", "v", "b", "n", "m"],
			["Caps", "Space", "Del"],
		],
		number: [
			["1", "2", "3"],
			["4", "5", "6"],
			["7", "8", "9"],
			["0", "Del"],
		],
	};

	return (
		<div className='keyboard-container'>
			{keys[type].map((row, rowIndex) => (
				<div key={rowIndex} className='key-row'>
					{row.map((key) => (
						<button
							key={key}
							onClick={() => {
								if (key === "Caps") {
									toggleCapsLock(); // Toggle Caps Lock
								} else {
									onKeyPress(key);
								}
							}}
							className={`key-button ${key === "Space" ? "space-key" : key === "Caps" ? "caps-key" : ""} ${isCapsLockOn && key === "Caps" ? "caps-on" : ""}`}>
							{key === "Space" ? "␣" : key === "Caps" ? "Caps Lock" : isCapsLockOn && type === "text" ? key.toUpperCase() : key}
						</button>
					))}
				</div>
			))}
		</div>
	);
};

function Welcome({ onStart }) {
	const [showModal, setShowModal] = useState(false);
	const [name, setName] = useState("");
	const [mobile, setMobile] = useState("");
	const [error, setError] = useState("");
	const [activeInput, setActiveInput] = useState(null);
	const [isCapsLockOn, setIsCapsLockOn] = useState(true); // Caps Lock state
	const nameInputRef = useRef(null);
	// Focus the name input field when the modal opens
	useEffect(() => {
		if (showModal && nameInputRef.current) {
			nameInputRef.current.focus();
			setActiveInput("name"); // Set active input to name
		}
	}, [showModal]);
	const validateInput = () => {
		// Allow any format but ensure the mobile number has at least 7 digits and contains only numbers, spaces, dashes, or parentheses
		const cleanedMobile = mobile.replace(/\D/g, ""); // Remove all non-numeric characters
		if (name.trim() === "" || cleanedMobile.length < 7) {
			setError("Please enter a valid name and a mobile number with at least 7 digits");
			return false;
		}
		setError("");
		return true;
	};

	const handleStartGame = () => {
		if (validateInput()) {
			onStart(name, mobile); // Pass name and mobile to the parent (App)
			setShowModal(false);
		}
	};

	const handleKeyPress = (key) => {
		if (activeInput === "name") {
			if (key === "Del") {
				setName((prev) => prev.slice(0, -1)); // Delete last character for name
			} else if (key === "Space") {
				setName((prev) => prev + " "); // Add space
			} else {
				setName((prev) => prev + (isCapsLockOn ? key.toUpperCase() : key)); // Add the pressed key to name input
			}
		} else if (activeInput === "mobile") {
			if (key === "Del") {
				setMobile((prev) => prev.slice(0, -1)); // Delete last character for mobile
			} else {
				setMobile((prev) => prev + key); // Add the pressed key to mobile input
			}
		}
	};

	const toggleCapsLock = () => {
		setIsCapsLockOn((prev) => !prev); // Toggle the Caps Lock state
	};


	return (
		<div className='welcome-screen'>
			<div className='title-container'>
				<h1 className='game-title'>MEMORY GAME</h1>
			</div>
			<div className='instructions-container'>
				<h2 className='how-to-play-title'>HOW TO PLAY</h2>
				<p className='instructions-text-title'>Players have a chance to test their Memory Skills!</p>
				<p className='instructions-text'>
					The player turns over 2 cards. If the pictures match, the player keeps the cards and tries again for another match. If they do not match, the cards are turned over again, and the player takes another turn. Every correct match gains the player 20 points, while every incorrect match loses the player 10 points.
					Each player must try to remember where they have seen cards so they can make all the matches within the given time.
				</p>
			</div>
			<button className='start-button' onClick={() => setShowModal(true)}>
				START
			</button>

			{/* Input Modal */}
			{showModal && (
				<div className='input-modal'>
					<div className='modal-content'>
						<h3>Enter Your Details</h3>
						<input type='text' placeholder='Name' ref={nameInputRef} value={name} onFocus={() => setActiveInput("name")} className='input-field' />
						<input type='tel' placeholder='Mobile Number' value={mobile} onFocus={() => setActiveInput("mobile")} className='input-field' />
						{error && <p className='error-message'>{error}</p>}
						<button onClick={handleStartGame} className='submit-button'>
							Submit
						</button>
						<button onClick={() => setShowModal(false)} className='cancel-button'>
							Cancel
						</button>
					</div>
					{activeInput && <VirtualKeyboard onKeyPress={handleKeyPress} type={activeInput === "name" ? "text" : "number"} isCapsLockOn={isCapsLockOn} toggleCapsLock={toggleCapsLock} />}
				</div>
			)}
		</div>
	);
}

export default Welcome;
