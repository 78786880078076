import React, { useState, useEffect } from "react";
import Tile from "./Tile";
import Sidebar from "./Sidebar";
import Success from "./Success";
import GameOver from "./GameOver";

function GamePlayingArea({ tiles, setTiles, setGameData, onStart, sendGameData }) {
	const [flippedTiles, setFlippedTiles] = useState([]); // Track flipped tiles
	const [unmatchedTiles, setUnmatchedTiles] = useState([]);
	const [result, setResult] = useState("LET'S PLAY");
	const [gameOver, setGameOver] = useState(null);
	const [score, setScore] = useState(0);
	const [time, setTime] = useState(45); // Game timer
	const [send, setSend] = useState(false); // Game timer

	// Timer logic
	useEffect(() => {
		if (time === 0) {
			setGameOver(false); // Game over if time runs out
			setGameData({ time: time, score: score });
		}
		if (time > 0 && gameOver === null) {
			const timer = setInterval(() => {
				setTime(time - 1);
			}, 1000);
			return () => clearInterval(timer);
		}
	}, [time, score, gameOver, setGameData]);

	// Handle tile click
	const handleTileClick = (index) => {
		// Prevent flipping if tile is already matched or two tiles are already flipped
		if (tiles[index].matched || flippedTiles.length === 2 || flippedTiles.includes(index)) return;

		const newFlippedTiles = [...flippedTiles, index];
		setFlippedTiles(newFlippedTiles);

		// If two tiles are flipped, check if they match
		if (newFlippedTiles.length === 2) {
			const [firstTileIndex, secondTileIndex] = newFlippedTiles;
			const firstTile = tiles[firstTileIndex];
			const secondTile = tiles[secondTileIndex];

			if (firstTile.icon === secondTile.icon) {
				// Match found, mark the tiles as matched
				const newTiles = tiles.map((tile, idx) => (idx === firstTileIndex || idx === secondTileIndex ? { ...tile, matched: true } : tile));
				setTiles(newTiles);
				setScore(score + 20); // Increase score for a match
				setResult("MATCH");
				setTimeout(() => {
					// After vibration, hide matched tiles
					const hiddenTiles = newTiles.map((tile, idx) => (idx === firstTileIndex || idx === secondTileIndex ? { ...tile, visible: false } : tile));
					setTiles(hiddenTiles);
					setFlippedTiles([]);
				}, 900); // Delay to allow the vibration animation to play
			} else {
				setTimeout(() => {
					setUnmatchedTiles(newFlippedTiles);
					setTimeout(() => {
						setUnmatchedTiles([]);
					}, 1500);
				}, 500);
				// No match, flip the tiles back after 1 second
				setTimeout(() => {
					setFlippedTiles([]);
				}, 1000);
				setScore(score - 10); // Decrease score for a mismatch
				setResult("TRY AGAIN");
			}
		}
	};

	// Check if all tiles are matched (i.e., the game is won)
	useEffect(() => {
		if (tiles.every((tile) => tile.matched)) {
			setTimeout(() => {
				setGameOver(true); // Game success if all pairs are matched
				setGameData({ time: time, score: score });
			}, 1000);
		}
	}, [tiles, time, score, gameOver, setGameData]);

	useEffect(() => {
		if (gameOver !== null && send === false) {
			sendGameData(gameOver);
			setSend(true);
		}
	}, [send, gameOver, sendGameData]);

	return (
		<div className='screen'>
			<div className='grid-container'>
				{tiles.map((tile, index) => (
					<Tile
						key={index}
						tile={tile}
						isFlipped={flippedTiles.includes(index) || tile.matched}
						onClick={() => handleTileClick(index)}
						vibrate={!tile.matched && unmatchedTiles.includes(index)} // Add vibrate class if matched
						hidden={!tile.visible} // Hide the tile if visibility is false
					/>
				))}
				{gameOver === true && <Success onStart={onStart} />}
				{gameOver === false && <GameOver onStart={onStart} />}
			</div>
			<Sidebar time={time} result={result} score={score} preview={false} />
		</div>
	);
}

export default GamePlayingArea;
