// src/components/Preview.js
import React, { useEffect, useState } from "react";
import Tile from "./Tile";
import Sidebar from "./Sidebar";
function Preview({ onPreviewComplete, tiles }) {
	const [countdown, setCountdown] = useState(3);

	useEffect(() => {
		if (countdown === 0) {
		  onPreviewComplete();
		} else {
			const timer = setTimeout(() => {
				setCountdown(countdown - 1);
			}, 1000);
			return () => clearTimeout(timer);
		}
	}, [countdown, onPreviewComplete]);

	return (
		<div className='screen'>
			<div className='grid-container'>
				{countdown > 0 && (
					<div className='countdown-overlay'>
						<div key={countdown} className={`countdown-text`}>
							{countdown > 0 ? countdown : "GO!"}
						</div>
					</div>
				)}
				{tiles.map((tile, index) => (
					<Tile key={index} tile={tile} isFlipped={true} onClick={() => null} />
				))}
			</div>
			<Sidebar time={0} result={""} score={0} preview={true} />
		</div>
	);
}

export default Preview;
